import Multiselect from "multiselect-react-dropdown";
import { ReactFragment, ReactNode } from "react";
import { FileError } from "react-dropzone";
export type Loading = "idle" | "loading" | "succeeded" | "failed";
export type SnackbarStatus = "danger" | "info" | "success" | "warning";
export enum Event {
  sessionExpired = "sessionExpired",
}
export interface Auth0Profile {
  given_name: string;
  name: string;
  email_verified: boolean;
  email: string;
  sub: string;
  "https://claims.cimpress.io/account": string;
  "https://claims.cimpress.io/canonical_id": string;
  "https://claims.cimpress.io/tenants": string[];
  updated_at: string;
}
export interface AuthenticationProps {
  isAuthenticating: boolean;
  profile?: Auth0Profile;
  authenticationError?: Error;
  accessToken: string;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}
export interface ITableOption {
  label: string | ReactFragment | ReactNode;
  value: string;
}
export interface IViewOptionType extends IViewType {
  label: ReactFragment | ReactNode;
  value: string;
}
export interface IViewType {
  viewId: string;
  name: string;
  createdAt: string | undefined;
  savedFilters: ISavedFilters[];
  unsavedFilters: IFilter[];
  selectedHeaders: string[];
  sortColumn: string | undefined;
  sortOrder: string | undefined;
  shared: boolean;
  userId: string;
  visibleColumns: string[];
  quickFilters: IFilter[] | [];
}
export interface IRow {
  [key: string]: string;
}
export interface IFilter {
  column: string;
  operator: string;
  value: string[];
}
export interface ISavedFilters {
  filterId: string;
  name: string;
  filters: IFilter[];
}

export interface ISearch {
  [key: string]: string;
}

export interface IProspectorSearch {
  country: string;
  field: string;
  fieldValue: string[];
}
export interface IIndustryMap {
  [key: string]: string[];
}
export type EnrichmentRecord = {
  created_at: string;
  enrichment_id: string;
  file_name: string;
  processed_records: number;
  tenant: string;
  enriched_data_url?: string;
  total_records: number;
  user_email: string;
  user_name: string;
  enriched_count?: number;
  non_enriched_count?: number;
};

export type EnrichmentResponse = {
  code: string;
  count: number;
  user_data: EnrichmentRecord[];
};

export interface ITenantOption {
  label: string | ReactFragment | ReactNode;
  value: string;
}
export interface ITenants{
  tenants:string[]
}
export interface IBulkEnrichmentType {
  enrichmentId: string;
  total_records: number;
  error: ValidateError | string | null; 
  enrichHistoryData: EnrichmentResponse;
  isLoading: string;
  enrichProgressData: EnrichmentRecord;
  isFilteredData: boolean;
  FilteredData: EnrichmentRecord[];
  dateFrom: string | null;
  dateTo: string | null;
  isLoadingInfo: string;
  tenantLimitReached: boolean;
  progressLoading: boolean;
  tenant:ITenantOption;
  selectedTenant:string;
  enrichStatus:string;
  historyTableRowIndex: number | null;
  sheetValidationErrors: boolean;
  isFilePresent: boolean;
  files: UploadableFiles[];
  isLoadingFileValidation:string;
  currentTime: string;
  tenantsList: ITenants;
}
export interface IViewStateType {
  selectedTable: string;
  tableList: string[];
  viewsList: IViewType[];
  selectedView: IViewType | null;
  selectedHeaders: string[];
  rows: IRow[][];
  pageNumber: number;
  pageSize: number;
  pageCount: number;
  columnOrder: string[];
  visibleColumns: string[];
  selectedAvailableColumn: string;
  selectedVisibleColumn: string;
  notEditableColumns: string[];
  isLoading: Loading;
  apiError: string;
  snackbar: boolean;
  snackbarMessage?: string;
  snackbarStatus: SnackbarStatus;
  savedFilters: ISavedFilters[];
  appliedSavedFilters: ISavedFilters[];
  unsavedFilters: IFilter[];
  sortColumn: string;
  sortOrder: string;
  headerChecked: boolean;
  selectedRowsIndexes: number[];
  totalRecords: number;
  renderVisibleColumns: boolean;
  industryMap: IIndustryMap;
  fetchData: boolean;
  callViewsApi: boolean;
  callFilterApi: boolean;
  statusArr: IStatus[][];
  allMappings: { [key: string]: { [key: string]: string } };
  subSegments: string[] | any[];
  loadingIndustryRows: { [key: string]: boolean };
  loadingWebsiteRows: { [key: string]: boolean };
  loadingEmployeeRows: { [key: string]: boolean };
  allLoading: boolean;
  userId: string;
  quickFilters: IFilter[];
  correctRecordFilter: boolean;
  overwrittenFilter: boolean;
  commentedFilter: boolean;
  validationFilter: string | undefined;
  permissionError: string;
  filterOP: string;
  detailedViewTable: string;
  isDetailedView: false;
  inputFields: { [key: string]: string };
  firmographics: { [key: string]: string };
  customFields: { [key: string]: string };
  linkedCustomerIds: [{ [key: string]: string }];
  businessFields: { [key: string]: string };
  cIdPageNumber: number;
  isLinkedCid: boolean;
  clearLinkedCidSearch: boolean;
  initialDetailedView: boolean;
  DetailedViewRow: IRow;
  selectedLinkUnlinkCustomerIds: string[];
  metaData: { [key: string]: { [key: string]: string } };
  searchedCorrelationIds: string[];
  searchedCustomerIds: string[];
  enrichedValues: { [key: string]: string };
  dataProviderResponse: { [key: string]: any };
  defaultOrgIdMapping: { [key: string]: string };
  prospectIsLoading: Loading;
  prospectorColumns: string[];
  prospectorRows: IRow[][];
  prospectorTotalRecords: number;
  isProspectorFiltered: boolean;
  prospectorFilteredData: IProspectorSearch;
  groupList: string[];
  checkedStateCorrect: boolean[];
  dependencyCells: string[];
  currentRowIndex: number | null;
}

export interface IResponseRow {
  [key: string]: string;
}
export interface IStatus {
  [key: string]: string;
}
export enum Status {
  Correct = "correct",
  Incorrect = "incorrect",
  NotSure = "notSure",
  NotVerified = "notVerified",
}

export interface ISerializedError {
  message?: string;
  code?: string;
}

export interface IViewRequestData {
  currentPage: number;
  size: number;
  viewId: string;
  savedFilters: ISavedFilters[];
  unsavedFilters: IFilter[];
  selectedTable: string;
  selectedColumns: string[];
  sortingOrder?: string;
  sortingColumn?: string;
  isSearch?: boolean;
  searchResponse?: ISearch;
  userId: string;
  isApplyFilters?: boolean;
  isSort?: boolean;
  isRefresh?: boolean;
  visibleColumns: string[];
  quickFilters?: IFilter[];
  filterOp: string;
}

export interface IProspectorRequestData {
  currentPage: number;
  size: number;
  fieldSearchResult?: IProspectorSearch;
}
export enum ReactAppEnvironment {
  Production = "production",
  Staging = "staging",
}

export enum DefaultView {
  Default = "All Columns Visible",
}
export interface IUpdateData {
  validationIds?: string[];
  label?: string;
  tableName: string;
  targetColumns?: string[];
  rowBeforeUpdate?: IRow;
  updatedRow?: IRow;
  fields?: string[];

  action: string;
  identifierId?: string;
  identifier?: string;
  viewName?: string;
  userId?: string;
}

export interface ISessionData {
  viewId: string;
  userId: string;
  selectedTable: string;
  unsavedFilters: IFilter[];
  selectedColumns?: string[];
  visibleColumns?: string[];
  sortingOrder?: string;
  sortingColumn?: string;
  pageSize?: number;
  pageCount?: number;
  action?: string;
  identifier?: string;
  identifierId?: string;
  fields?: string[];
  updatedRecord?: { [key: string]: string };
  targetColumns?: string[];
  label?: string;
  rows?: IRow[][];
  isAction?: boolean;
  isEditCols?: boolean;
  currentPage: number;
  size: number;
  savedFilters: ISavedFilters[];
}

export enum DeleteCount {
  Single = "single",
  All = "all",
  Null = "null",
}

export interface DetailedViewSectionsItem {
  header: string;
  value: string;
}

export interface DetailedViewSectionsData {
  [header: string]: string;
}

export interface DetailedViewSectionsProps {
  title: string;
  data: DetailedViewSectionsData[] | DetailedViewSectionsData;
  tab: string;
  customerIdentifier: string;
}

export interface DataProviderViewProps {
  title: string;
}

export interface ILinkUnlinkData {
  tableName: string;
  identifierId: string;
  actor: string;
  action: string;
  idsToLink?: string[];
  idsToUnlink?: string[];
  organizationId?: string;
}

export interface DetailedViewSelectProps {
  toggleLinkingWrapperHeight: () => void;
  multiselectRef: React.RefObject<Multiselect>;
  linkOperator: string;
  displayIds: string[];
  linkedCIds: DetailedViewSectionsData[];
}

export interface Option {
  value: string;
  label: string;
}

export enum LinkButton {
  Unlink = "Unlink",
  Link = "Link",
}
export enum DashboardType {
  evo = "EVO",
  enrichment = "Enrichment",
}
export interface UploadableFiles {
  file: File;
  errors: FileError[];
}
export interface SingleFileUploadProps {
  setFilePresent: React.Dispatch<React.SetStateAction<boolean>>;
  isFilePresent: boolean;
}
export interface Country {
  alpha2: string;
  alpha3: string;
  countryCallingCodes: string[];
  currencies: string[];
  emoji: string;
  ioc?: string;
  name?: string;
  status?: string;
}

export interface GenerateLinkResponse {
  data: {
    signedEmbedUrl: string;
    _links: {
      self: {
        href: string;
      };
      configuration: {
        href: string;
      };
    };
  };
}

export interface IValidateFileResponse {
  status: string;                   
  message: string;                   
  data: IErrorResponse | IFileLinkResponse | [];  
}

interface IFileLinkResponse {
  file_link: string;                 // A file link, if the response is successful
}
export interface IErrorResponse {
  0: { error: string };              // Error object as the first element in the array
  1: number;                         // Numeric status code as the second element in the array
}


export interface ValidateError {
  status: string;
  message: string;
  data: { error?: string }[] | null; 
}