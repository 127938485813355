import axios from "axios";
import { axiosClient } from "../axiosClient";

import {
  IFilter,
  ILinkUnlinkData,

  IProspectorRequestData,

  ISessionData,
  IUpdateData,
  IViewRequestData
} from "../types";
export enum Action {
  overWrite = "overwrite",
}
export enum linkAction {
  link = "link",
  unlink = "unlink"
}
interface IValidateBody {
  columns: string[];
  label: string;
  table_name: string;
  ids: string[];
}
interface IOverwriteRecord {
  [key: string]: string;
}
export const columnData = async (userId: string) => {
  const response = await axiosClient.get(`/views/columns/${userId}`);
  return response.data.columns;
};

export const getData = async (params: IViewRequestData) => {
  const {
    currentPage,
    size,
    viewId,
    savedFilters,
    unsavedFilters,
    selectedTable,
    selectedColumns,
    sortingColumn,
    sortingOrder,
    isSearch,
    searchResponse,
    userId,
    isApplyFilters,
    isSort,
    isRefresh,
    visibleColumns,
    quickFilters,
    filterOp,
  } = params;
  const offset = currentPage * size;
  const data = {
    view_id: viewId,
    saved_filters: savedFilters,
    unsaved_filters: unsavedFilters,
    table_name: selectedTable,
    selected_columns: selectedColumns,
    sorting_column: sortingColumn || "",
    sorting_order: sortingOrder || " ",
    is_search: isSearch,
    search_response: searchResponse,
    user_id: userId,
    is_apply_filter: isApplyFilters,
    is_sort: isSort,
    is_refresh: isRefresh,
    visible_columns: visibleColumns,
    quick_filters: quickFilters,
    filter_op: filterOp,
  };
  const response = await axiosClient.post(
    `/views/${viewId}/enrichedData?offset=${offset}&limit=${size}`,
    data
  );
  return response.data;
};

export const getProspectorData = async (params: IProspectorRequestData) => {
  const {
    currentPage,
    size,
    fieldSearchResult,
  } = params;
  const data = {
    field_search_result: fieldSearchResult,
  };
  const { field, country, fieldValue } = data.field_search_result || {};
  const response = (field && data.field_search_result?.fieldValue[0]) ?
    await axiosClient.get(`/prospector/search/${data.field_search_result?.field}/${country}/${fieldValue ? fieldValue[0] : ''}?page=${currentPage + 1}&limit=${size}`)
    : await axiosClient.get(`/prospector/data/${country}?page=${currentPage + 1}&limit=${size}`)
  return response.data;
};

export const updateLabel = async (params: IUpdateData) => {
  const {
    action,
    tableName,
    targetColumns,
    label,
    identifierId,
    viewName,
    fields,
    userId,
    updatedRow,
    rowBeforeUpdate,
    validationIds
  } = params;
  // const ids = params.rows?.map((row: IRow[]) => {
  //   let id = "";
  //   row.forEach((cell: IRow) => {
  //     if ("ENRICHMENT_CORRELATION_ID" in cell) {
  //       id = cell["ENRICHMENT_CORRELATION_ID"];
  //     }
  //   });
  //   return id;
  // });
  const data =
    action === Action.overWrite
      ? {
        fields,
        action,
        identifier: params.identifier,
        identifier_id: identifierId,
        table_name: tableName,
        updated_record: updatedRow,
        existing_record: rowBeforeUpdate,
        actor: userId,
      }
      : { columns: targetColumns, label, table_name: tableName, ids: validationIds };
  const response = await axiosClient.put("/enrichedRecords", data);
  if (response.status === 200) {
    action === Action.overWrite
      ? store_logs(
        action,
        userId!,
        identifierId!,
        fields!,
        tableName,
        viewName!,
        rowBeforeUpdate,
        updatedRow
      )
      : store_validate_logs(
        action,
        userId!,
        validationIds!,
        targetColumns!,
        tableName,
        viewName!,
        JSON.parse(response.config.data)
      );
  }

  return {
    message: response.data.message,
    action,
    field: fields ? fields[0] : "",
  };
};
export const getTablesOptions = async (userId: string) => {
  const response = await axiosClient.get(`/tables?user_id=${userId}`);
  return response.data;
};

export const getUserGroups = async (userId: string) => {
  const response = await axiosClient.get(`/groups?user_id=${userId}`);
  return response.data;
}
export const getViewOptions = async (userId: string, tableName: string) => {
  const response = await axiosClient.get(
    `/views?user_id=${userId}&table_name=${tableName}`
  );
  return response.data;
};
export const saveView = async (viewData: any) => {
  const {
    userId,
    tableName,
    savedFilters,
    unsavedFilters,
    sortingColumn,
    sortingOrder,
    visibility,
    name,
    selectedHeaders,
    quickFilters,
    visibleColumns,
  } = viewData;

  const data = {
    table_name: tableName,
    saved_filters: savedFilters,
    unsaved_filters: unsavedFilters,
    sorting_column: sortingColumn,
    sorting_order: sortingOrder,
    name,
    visibility,
    user_id: userId,
    selected_columns: selectedHeaders,
    quick_filters: quickFilters,
    visible_columns: visibleColumns,
  };
  const response = await axiosClient.post(`/views`, data);
  if (response.status === 200) {
    const message = visibility
      ? "View saved successfully and shared with organization"
      : "View saved successfully";
    return { ...response.data, message: message };
  }
  return { message: "Failed action" };
};
export const saveUserFilters = async (
  userId: string,
  tableName: string,
  filters: IFilter[],
  filterName: string
) => {
  const data = {
    user_id: userId,
    filter_name: filterName,
    filters,
    table_name: tableName,
  };
  const response = await axiosClient.post("/filters", data);
  if (response.status === 200) {
    return {
      message: `Filter ${filterName} has been saved successfully`,
      filterId: response.data.filter_id,
    };
  }
  return { message: "Failed action" };
};

export const getAllFilters = async (userId: string, tableName: string) => {
  const response = await axiosClient.get(
    `/filters?user_id=${userId}&table_name=${tableName}`
  );
  return response.data;
};
export const updateFilter = async (
  updatedFilters: IFilter[],
  filterId: string
) => {
  const data = {
    filters: updatedFilters,
    filter_id: filterId,
  };
  const response = await axiosClient.put(`/filters`, data);
  return response.data;
};

export const deleteFilter = async (filterName: string, filterId: string) => {
  const response = await axiosClient.delete(`/filters/${filterId}`);
  if (response.status === 200) {
    const popUpMessage = `Filter ${filterName} has been deleted successfully`;
    return { message: popUpMessage };
  }
  return { message: "Failed action" };
};

export const getIndustryMapping = async () => {
  const response = await axiosClient.get("/industryMapping");
  return response.data;
};

export const getAllComments = async (
  table_name: string,
  id: string,
  field: string
) => {
  const response = await axiosClient.get(
    `/enrichedRecords/comments/${table_name}/${id}/${field}`
  );
  return response.data.map((comment: any) => ({
    ...comment,
    commentedBy: comment.commented_by,
    commentText: comment.comment_text,
    createdAt: comment.created_at,
  }));
};
export const updateComment = async (
  identifierId: string,
  id: string,
  columnName: string,
  field: string,
  value: string | boolean,
  userId: string
) => {
  const data = {
    id,
    field,
    value,
    user_id: userId,
    identifier_id: identifierId,
    column_name: columnName,
  };
  await axiosClient.put(`/enrichedRecords/comments`, data);
};
export const addComment = async (
  table_name: string,
  id: string,
  field: string,
  value: string,
  userEmail: string
) => {
  const response = await axiosClient.post(
    `/enrichedRecords/comments/${table_name}/${id}/${field}`,
    { user_email: userEmail, comment_text: value }
  );
  return response.data;
};
export const store_logs = async (
  action: string,
  userEmail: string,
  cid: string,
  fields: string[],
  tableName: string,
  viewName: string,
  existing_record?: IOverwriteRecord,
  updatedRecord?: IOverwriteRecord
) => {
  const response = await axiosClient.post(`/evologs`, {
    actor: userEmail,
    changes: {
      action: action,
      description: {
        before_update: existing_record,
        after_update: updatedRecord,
      },
    },
    customer_id: cid,
    fields: fields,
    table_name: tableName,
    tenant: tableName,
    view_name: viewName,
  });
  return response.data;
};
export const store_validate_logs = async (
  action: string,
  userEmail: string,
  cid: string[],
  fields: string[],
  tableName: string,
  viewName: string,
  validate_data?: IValidateBody
) => {
  const response = await axiosClient.post(`/evologs`, {
    actor: userEmail,
    changes: { action: action, description: { status_change: validate_data } },
    customer_id: cid,
    fields: fields,
    table_name: tableName,
    tenant: tableName,
    view_name: viewName,
  });
  return response.data;
};

export const opensearchResults = async (
  tableName: string,
  searchParam: string,
  accessToken: string
) => {
  const headers = {
    Authorization: "Bearer " + accessToken,
    "Content-Type": "application/json",
  };
  const response = await axios.post(
    `https://integration-dc.mat.cimpress.io/customer-enrichment-api/portal/v0/search`,
    { table_name: tableName, search_param: searchParam },
    { headers }
  );
  return response.data;
};

export const deleteSingleView = async (viewId: string) => {
  return await axiosClient.delete(`/views/${viewId}`);
};

export const shareView = async (viewId: string) => {
  return await axiosClient.put(`/views/${viewId}`);
};
export const getAllMappings = async () => {
  const response = await axiosClient.get("/allMappings");
  return response.data;
};
export const segmentList = async (tenant: string) => {
  const response = await axiosClient.get(`/reseller-segments?tenant=${tenant}`);
  return response.data;
};

export const postSessionData = async (params: ISessionData) => {
  const {
    viewId,
    userId,
    selectedTable,
    selectedColumns,
    visibleColumns,
    isAction,
    isEditCols,
    currentPage,
    size,
    sortingColumn,
    sortingOrder,
    savedFilters,
    unsavedFilters,
  } = params;
  const data = {
    view_id: viewId,
    table_name: selectedTable,
    user_id: userId,
    selected_columns: selectedColumns,
    visible_columns: visibleColumns,
    is_action: isAction,
    is_edit_cols: isEditCols,
    sorting_column: sortingColumn || "",
    sorting_order: sortingOrder || " ",
    saved_filters: savedFilters,
    unsaved_filters: unsavedFilters,
  };
  const offset = currentPage * size;
  const response = await axiosClient.post(
    `/session?offset=${offset}&limit=${size}`,
    data
  );
  return response.data;
};

export const deleteSessionData = async (
  deleteCount: string,
  userId: string,
  viewId: string,
  tableName: string
) => {
  const response = await axiosClient.delete(
    `/session?delete_count=${deleteCount}&user_id=${userId}&view_id=${viewId}&table_name=${tableName}`
  );
  return response.data;
};

export const extendSessionData = async (key: string) => {
  const response = await axiosClient.put(`/session?key=${key}`);
  return response.data;
};

export const getSessionKeys = async (userId: string) => {
  const response = await axiosClient.get(`/session/keys?user_id=${userId}`);
  return response.data;
};

export const deleteSessionKeys = async (userId: string) => {
  const response = await axiosClient.delete(`/session/keys?user_id=${userId}`);
  return response.data;
};

export const postUserAcitivity = async (emailId: string, loginTime: string) => {
  const response = await axiosClient.post(`/evo-user-activity`, {
    email_id: emailId,
    login_time: loginTime,
  });
  return response.data;
};

export const detailedViewOverview = async (
  identifier: string,
  table_name: string
) => {
  const response = await axiosClient.get(
    `/enriched-records/${identifier}?table_name=${table_name}`
  );
  return response.data;
};

export const detailedViewDataProviders = async (
  identifier: string,
  table_name: string
) => {
  const response = await axiosClient.get(
    `/enriched-records/data-providers/${identifier}?table_name=${table_name}`
  );
  return response.data;
};

export const linkUnlinkCustomerIds = async (params: ILinkUnlinkData) => {
  const {
    tableName,
    identifierId,
    actor,
    action,
    idsToLink,
    idsToUnlink,
    organizationId,
  } = params;
  const data =
    action === linkAction.link
      ? {
        table_name: tableName.toUpperCase(),
        identifier_id: identifierId,
        actor: actor,
        action: action,
        ids_to_link: idsToLink,
        organization_id: organizationId,
      }
      : {
        table_name: tableName.toUpperCase(),
        identifier_id: identifierId,
        actor: actor,
        action: action,
        ids_to_unlink: idsToUnlink,
      };
  const response = await axiosClient.post(
    `/enriched-records/link-unlink`,
    data
  );
  return response.data;
};

export const defaultOrganizationIds = async (
) => {
  const response = await axiosClient.get(
    `/default-org-ids`
  );
  return response.data;
};

export const bulkEnrichmentUpload = async (formData: FormData) => {
  const response = await axiosClient.post(`/bulk-enrichments/trigger`, formData, { 
    transformRequest: [(data, headers) => {
      delete headers['Content-Type'];
      return data;
    }],});
  return response.data;
};

export const EnrichmentHistory = async (user: string) => {
  const response = await axiosClient.get(`/bulk-enrichments/users/${user}/history`);
  return response.data;
};

export const EnrichmentIdStatus = async (id: string) => {
  const response = await axiosClient.get(`/bulk-enrichments/${id}/info`);
  return response.data;
};

export const TenantNumberOfRecords = async (tenant: string) => {
  const response = await axiosClient.get(`/bulk-enrichments/tenants/${tenant}/stats`);
  return response.data;
};

export const docIntent = async (formData: FormData) => {
  const response = await axiosClient.post('/docIntent', formData, {
    transformRequest: [(data, headers) => {
      delete headers['Content-Type'];
      return data;
    }],
  })
  return response
};

export const sheetsValidate = async (formData: FormData) => {
  const response = await axiosClient.post('/bulk-enrichments/sheets:validate', formData, { 
    transformRequest: [(data, headers) => {
      delete headers['Content-Type'];
      return data;
    }],});
  return response.data;
};

export const getTenants = async (user_id: string) => {
  const response = await axiosClient.get(`/bulk-enrichments/tenants?user_id=${user_id}`);
  return response.data;
};
