import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bulkEnrichmentUpload, EnrichmentHistory, EnrichmentIdStatus, getTenants, TenantNumberOfRecords } from "../../hooks/view-data";
import { IBulkEnrichmentType,ValidateError } from "../../types";
import _ from "lodash";
import { sheetsValidate } from "../../hooks/view-data";
import moment from "moment";

const initialState: IBulkEnrichmentType = {
  enrichmentId: "",
  total_records: 0,
  error: null as ValidateError | string | null,
  enrichHistoryData: {
    code: "",
    count: 0,
    user_data: []
  },
  isLoading: "",
  enrichProgressData: {
    created_at: "",
    enrichment_id: "",
    file_name: "",
    processed_records: 0,
    tenant: "",
    total_records: 0,
    user_email: "",
    user_name: "",
    enriched_count: 0,
    non_enriched_count: 0,
  },
  isFilteredData: false,
  FilteredData: [],
  dateFrom: null,
  dateTo: null,
  isLoadingInfo: "",
  tenantLimitReached: false,
  progressLoading: false,
  tenant : {'label': 'test','value': 'test'},
  selectedTenant:"",
  enrichStatus: "unknown",
  historyTableRowIndex: null,
  sheetValidationErrors: false,
  isFilePresent:false,
  files: [],
  isLoadingFileValidation:"",
  currentTime: '',
  tenantsList:{'tenants':[]},
}

export const bulkEnrichmentTrigger = createAsyncThunk(
  "bulkEnrichment/bulkEnrichmentTrigger",
  async (
    data: {
      formData: FormData
    },
    { rejectWithValue }
  ) => {
    try {
      const { formData } = data;
      return await bulkEnrichmentUpload(formData);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const TenantRecordStatus = createAsyncThunk(
  "bulkEnrichment/TenantRecordStatus",
  async (
    data: {
      tenant: string
    },
    { rejectWithValue }
  ) => {
    try {
      const { tenant } = data;
      return await TenantNumberOfRecords(tenant);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
)
export const EnrichmentHistoryData = createAsyncThunk(
  "bulkEnrichment/EnrichmentHistory",
  async (
    data: {
      user: string
    },
    { rejectWithValue }
  ) => {
    try {
      const { user } = data;
      return await EnrichmentHistory(user);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
)
export const EnrichmentIdInfo = createAsyncThunk(
  "bulkEnrichment/EnrichmentIdStatus",
  async (
    data: {
      id: string
    },
    { rejectWithValue }
  ) => {
    try {
      const { id } = data;
      return await EnrichmentIdStatus(id);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
)
export const userTenants = createAsyncThunk(
  "bulkEnrichment/tenants",
  async (
    data: {
      user_id: string
    },
    { rejectWithValue }
  ) => {
    try {
      const { user_id } = data;
      return await getTenants(user_id);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
)

export const validateFile = createAsyncThunk(
  "bulkEnrichment/validateFile",
  async (
    data: {
      formData: FormData
    },
    { rejectWithValue }
  ) => {
    try {
      const { formData } = data;
      return await sheetsValidate(formData);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);


const bulkEnrichmentSlice = createSlice({
  name: "bulkEnrichment",
  initialState,
  reducers: {
    setRecordsInFile(state, action) {
      state.total_records = action.payload
    },
    setEnrichHistoryData(state, action) {
      state.enrichHistoryData = action.payload
    },
    setUploadError(state, action) {
      state.error = action.payload
    },
    setEnrichStatus(state, action) {
      state.enrichStatus = action.payload
    },
    setEnrichProgress(state, action) {
      state.enrichProgressData = action.payload
    },
    setIsFilteredData(state, action) {
      state.isFilteredData = action.payload
    },
    setFilteredData(state, action) {
      state.FilteredData = action.payload
    },
    setFromDate(state, action) {
      state.dateFrom = action.payload
    },
    setToDate(state, action) {
      state.dateTo = action.payload
    },
    setProgressLoading(state, action) {
      state.progressLoading = action.payload
    },
    setTenant(state, action) {
      state.selectedTenant = action.payload
    },
    setHistoryTableRowIndex(state,action){
      state.historyTableRowIndex = action.payload
    },
    setSheetValidationErrors(state, action){
      state.sheetValidationErrors = action.payload
    },
    setisFilePresent(state, action){
      state.isFilePresent = action.payload
    },
    setFiles(state, action){
      state.files = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(EnrichmentHistoryData.pending, (state, action) => {
        state.isLoading = "loading";
      })
      .addCase(EnrichmentHistoryData.fulfilled, (state, action) => {
        if (!_.isUndefined(action.payload)) {
          state.enrichHistoryData = action.payload;
          state.isLoading = "succeeded";
          const momentDate = moment();
          state.currentTime = momentDate.format('YYYY-MM-DD HH:mm:ss')
        }
      })
      .addCase(EnrichmentHistoryData.rejected, (state, action:any) => {
        if(_.isUndefined(action.payload) || action.payload.code !== "notFound"){
          state.isLoading = "idle";
          const momentDate = moment();
          state.currentTime = momentDate.format('YYYY-MM-DD HH:mm:ss')
        }
        else if (!_.isUndefined(action.payload) && action.payload.code === "notFound") {
          state.enrichHistoryData = {
            code: "",
            count: 0,
            user_data: []
          };
          const momentDate = moment();
          state.currentTime = momentDate.format('YYYY-MM-DD HH:mm:ss')
          state.isLoading = "succeeded"
        }
       
      })
      .addCase(EnrichmentIdInfo.pending, (state, action) => {
        state.isLoadingInfo = "loading"
      })
      .addCase(EnrichmentIdInfo.fulfilled, (state, action) => {
        if (!_.isUndefined(action.payload)) {
          state.enrichProgressData = action.payload;
          state.isLoadingInfo = "succeded";
        }
      })
      .addCase(EnrichmentIdInfo.rejected, (state) => {
        state.isLoading = "idle";
        state.isLoadingInfo = "idle";
      })
      .addCase(TenantRecordStatus.fulfilled, (state, action) => {
        if (!_.isUndefined(action.payload)) {
          state.tenantLimitReached = action.payload.daily_limit_reached;
        }
      })
      .addCase(validateFile.pending, (state) => {
        state.isLoadingFileValidation = "loading"
      })
      .addCase(validateFile.fulfilled, (state, action) => {
        state.isLoadingFileValidation = "completed"
      })
      .addCase(validateFile.rejected, (state, action) => {
        state.isLoadingFileValidation = "completed"

      })
      .addCase(userTenants.fulfilled,(state,action) => {
        state.tenantsList = action.payload;
      })
      ;
  },
})
export const { setRecordsInFile, setUploadError, setEnrichProgress, setEnrichHistoryData, setIsFilteredData, setFilteredData, setFromDate, setToDate, setProgressLoading, setTenant, setEnrichStatus, setHistoryTableRowIndex, setSheetValidationErrors, setisFilePresent,setFiles} = bulkEnrichmentSlice.actions;
export default bulkEnrichmentSlice.reducer;